import './Navbar.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

export function CollapsibleExample() {
  return (
    <Navbar sticky="top" bg="primary" collapseOnSelect className="h-50 w-100 bg-gradient">
      <Container>
        <Navbar.Brand href="/" style={{fontSize: '27px', textAlign: 'left'}}>
          <img src='golfback.jpg' alt='some-logo' style={{ width: '20%', height: '20%', padding: '10px'}}/>{' '}
          AutoAdviser
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features" style={{fontSize: '20px'}}>Features</Nav.Link> */}
            {/* <Nav.Link href="#pricing" style={{fontSize: '20px'}}>Pricing</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Nav>
            <Nav.Link href="#deets" style={{fontSize: '20px'}}>More deets</Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}