// Import the functions you need from the SDKs you need
import React, { createContext } from 'react'
import { getApps, initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyBBH4ZkCAYouTA_Tsb37CbuqNxMki49M5Q",
    authDomain: "auto-market-info.firebaseapp.com",
    databaseURL: "https://auto-market-info-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "auto-market-info",
    storageBucket: "auto-market-info.appspot.com",
    messagingSenderId: "95206966334",
    appId: "1:95206966334:web:ee6b83d19e6991562d3d6f",
    measurementId: "G-BMW366CC3L",

  };

const FirebaseContext = createContext(null)
export { FirebaseContext }

// eslint-disable-next-line
export default ({ children }) => {
    let app = null;
    if (!getApps().length) {
        app = initializeApp(firebaseConfig)

        // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
        // site key and pass it to initializeAppCheck().
        const siteKey = '6LfFWR0pAAAAAPEhEnGsGMVUT8V4mMcEeb1M_5RO';
        // eslint-disable-next-line
        const _appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider(siteKey),
            isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
        });
    } else {
        app = getApps()[0];
    }
    return (
        <FirebaseContext.Provider value={ app }>
        { children }
        </FirebaseContext.Provider>
    )
}