import { useState } from 'react';
import Container from 'react-bootstrap/Container';

function getEcoStandardToNumber(eco: any) {
    switch (eco.toUpperCase()) {
        case 'EURO I': return 1;
        case 'EURO II': return 2;
        case 'EURO III': return 3;
        case 'EURO IV': return 4;
        case 'EURO V': return 5;
        case 'EURO VI': return 6;
        default:
            console.error('Missing eco standard');
            return 4;
    }
}

function getProdYearCoeff(year: any) {
    const currentYear = new Date().getFullYear();
    const yearsPassed = currentYear - year;
    if (yearsPassed <= 5) {
        return 2.3;
    } else if (yearsPassed > 5 && yearsPassed <= 10) {
        return 1.5;
    } else if (yearsPassed > 10 && yearsPassed <= 15) {
        return 1.3
    } else if (yearsPassed > 15 && yearsPassed <= 20) {
        return 1;
    } else if (yearsPassed > 20) {
        return 1.1;
    }

    console.error('Couldnt\'t calculate years coeff!');
    return 1;
}

function getEcoStandardCoeff(eco: any) {
    switch (eco) {
        case 1: return 1.1;
        case 2: return 1.4;
        case 3: return 1.1;
        case 4: return 1;
        case 5: return 0.8;
        case 6: return 0.4;
        default:
            console.error('Missing eco standard');
            return 4;
    }
}

function getKWCoeff(kw: any) {
    if (kw <= 55) {
        return 0.34;
    } else if (kw > 55 && kw <= 74) {
        return 0.54;
    } else if (kw > 74 && kw <= 110) {
        return 1.21
    } else if (kw > 110 && kw <= 150) {
        return 1.42;
    } else if (kw > 150 && kw <= 245) {
        return 1.84;
    } else if (kw > 245) {
        return 2.42;
    }

    console.error('Couldnt\'t calculate years coeff!');
    return 1;
}

function calculateFuel(kmsPerYear: any, consumption: any) {
    const kms = kmsPerYear / 100;
    const res = kms * consumption;
    return res;

}

export function ExpensesCalculator(props: any) {
    let [kmsPerYear, setKmsPerYear] = useState(15000);

    const fuelCost = (input : any) => {
        setKmsPerYear(Number(input.target.valueAsNumber));
    }

    const calculateTotalFuel = () => {
        if (!props.engine || !props.engine.combinedConsumption) {
            return 0;
        }

        let consumption = Number(props.engine.combinedConsumption.split(' ')[0]);
        const res = calculateFuel(kmsPerYear, consumption);
        return res.toFixed(2);
    }

    const calculateTotalFuelPrice = () =>{
        return Number(calculateTotalFuel()) * 2.80;
    }

    const displayEngine = () => {
        if (!props.engine || !props.engine.engine) {
            return 'Please select engine!';
        }

        return props.engine.engine;
    }

    const calculateYearTax = () => {
        if (!props.engine || !props.engine.power) {
            return 0;
        }

        let hp = Number(props.engine.power.replace(/\D/g,''));
        //1 hp = 0,7457 kW
        let kws = hp * 0.7457;
        const kwsCoeff = getKWCoeff(kws);
        const ecoStandard = getEcoStandardToNumber(props.engine.emissionStandard);
        const ecoCoeff = getEcoStandardCoeff(ecoStandard);
        const startYear = Number(props.engine.productionStartYear.replace(/\D/g,''));
        const yearCoeff = getProdYearCoeff(startYear);

        return ((kws* kwsCoeff) * yearCoeff * ecoCoeff);
    }

    const calculateRepairs = () => {
        if (!props.engine) {
            return 0;
        }

        return Number(props.currentPrice.replace(/\D/g,'')) * 0.03;
    }

    const calculateInsurance = () =>{
        if (!props.engine) {
            return 0;
        }

        return Number(props.currentPrice.replace(/\D/g,'')) * 0.05;
    }

    const calculateTotalExpenses = () => {
        return calculateInsurance() + calculateYearTax() + calculateTotalFuelPrice();
    }

    return (
        <Container>
            <h1>Expenses Calculator</h1>
            <h5>Engine: {displayEngine()}</h5>
            <br></br>
            <h4>Maintainance:</h4>
            Travelled distance(km) per year:
            <input onInput={fuelCost} defaultValue="15000" type="number" step="1000" style={{width:'70px'}}/>
            <div>Estimated consumption cost: {calculateTotalFuel()} litres (est. {calculateTotalFuelPrice().toFixed(2)} lv.) </div>
            <div>Estimated repairs: {calculateRepairs()} lv. </div>
            <br></br>
            <h4>Vehicle Taxes:</h4>
            <div>Government (Per year in Sofia): {calculateYearTax().toFixed(2)} lv.</div>
            <br></br>
            <h4>Insurance:</h4>
            <div>Auto insurance (non-required): {calculateInsurance().toFixed(2)} lv.</div>
            <br></br>
            <h3>Total expenses(for year): {calculateTotalExpenses().toFixed(2)} lv.</h3>


        </Container>
    );
}